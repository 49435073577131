<template>
  <div>
    <search-carrier-sim-card
      @submit="submitSearch"
      :carrier-account-id="carrierAccountId"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          v-for="option in batchOperationOptions"
          :key="option.value"
          :value="option.value"
          type="primary"
          @click="handleBatchOperation"
        >
          {{ option.label }}
        </a-button>

        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    >
      <div slot="iccid" slot-scope="iccid, record">
        <a @click="showInfoModal(record.id)">{{ iccid }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-sim-card
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!-- 批量操作组件 -->
    <component
      v-if="isShowBatchOperationModal"
      :is="currentBatchOperationComponent"
      :visible.sync="isShowBatchOperationModal"
      :iccids="selectedRowKeys"
      :carrier-account-id="carrierAccountId"
      :is-inventory="false"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findCarrierSimCards, exportCarrierSimCards } from '@/api/sim_card'
import Pagination from '@/components/Pagination'
import SearchCarrierSimCard from '@/views/sim_cards/carrier/Search'
import { findCarrierBatchOperationOptions } from '@/api/batch_operation'
import { exportExcel } from '@/api/excel'

export default {
  name: 'CarrierSimCardList',
  components: {
    SearchCarrierSimCard,
    Pagination,
    ShowSimCard: () => import('@/views/sim_cards/Show'),
    UpdateSimCardsInfo: () => import('@/views/batch_operations/UpdateCarrierSimCardsInfo'),
    BatchOperationForceNetDownCarrierSimCards: () => import('@/views/batch_operations/ForceNetDownCarrierSimCards'),
    BatchOperationCancelForceNetDownCarrierSimCards: () => import('@/views/batch_operations/CancelForceNetDownCarrierSimCards'),
    BatchOperationChangeCarrierProduct: () => import('@/views/batch_operations/ChangeSimCardsCarrierProduct')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false, // 是否显示详情弹窗
      showingId: 0, // 正在查看详情的记录id
      isShowBatchOperationModal: false, // 是否显示批量操作弹窗
      currentBatchOperation: '', // 当前批量操作
      batchOperationOptions: findCarrierBatchOperationOptions(),
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi'
        },
        {
          title: '短号',
          dataIndex: 'short_number'
        },
        {
          title: '卡片材质',
          dataIndex: 'card_type'
        },
        {
          title: '卡片尺寸',
          dataIndex: 'card_shape'
        },
        {
          title: '共享属性',
          dataIndex: 'traffic_share_property'
        },
        {
          title: '运营商套餐',
          dataIndex: 'carrier_product_name'
        },
        {
          title: '超套停',
          dataIndex: 'is_exceed_package_stop'
        },
        {
          title: '流量属性',
          dataIndex: 'traffic_property'
        },
        {
          title: '运营商状态',
          dataIndex: 'card_status'
        },
        {
          title: '开卡日期',
          dataIndex: 'open_card_date',
          sorter: true,
          sortOrder: this.sort.sort_field === 'open_card_date' ? this.sort.sort_order : false
        },
        {
          title: '运营商测试期',
          dataIndex: 'carrier_test_period_end_date'
        },
        {
          title: '运营商沉默期',
          dataIndex: 'carrier_silent_period_end_date'
        },
        {
          title: '运营商激活时间',
          dataIndex: 'carrier_activated_at'
        },
        {
          title: '入库时间',
          dataIndex: 'imported_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'imported_at' ? this.sort.sort_order : false
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '机卡分离',
          dataIndex: 'bind_status'
        },
        {
          title: '所属客户',
          dataIndex: 'top_agent_name'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '服务周期起始时间(激活时间)',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '后续周期个数',
          dataIndex: 'future_cycle_count'
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at'
        }
      ]
    },

    carrierAccountId() {
      return parseInt(this.$route.params.id)
    },

    // 当前批量操作组件
    currentBatchOperationComponent() {
      switch (this.currentBatchOperation) {
        case 'update_info':
          return 'UpdateSimCardsInfo'
        case 'force_net_down':
          return 'BatchOperationForceNetDownCarrierSimCards'
        case 'cancel_force_net_down':
          return 'BatchOperationCancelForceNetDownCarrierSimCards'
        case 'change_carrier_product':
          return 'BatchOperationChangeCarrierProduct'
        default:
          return ''
      }
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleBatchOperation(e) {
      this.currentBatchOperation = e.target.value
      this.isShowBatchOperationModal = true
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCarrierSimCards(Object.assign({ carrier_account_id: this.carrierAccountId },
        this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportCarrierSimCards(Object.assign({ carrier_account_id: this.carrierAccountId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
